<template>
  <main class="table">
    <app-table
      :headers="tableHeader"
      :data="logsData"
      :loading="isLoading"
      :isDropdown="true"
      :lighter="lighter"
    >
      <template slot="loading">
        <app-spinner />
      </template>

      <template slot="id" slot-scope="{ data }">
        <p class="table-line">
          <strong>{{ data.requestId }}</strong>
        </p>
      </template>

      <template slot="source" slot-scope="{ data }">
        <p v-if="data.request" class="table-line">{{ data.request.page.goto !== null ? 'URL' : 'HTML' }}</p>
        <div v-else class="none">
          <span />
        </div>
      </template>

      <template slot="render" slot-scope="{ data }">
        <p :class="['table-line', 'log-status', logStatus[data.renderStatus]]">
          <strong>{{ data.renderStatus }}</strong>
        </p>
      </template>

      <template slot="webhook" slot-scope="{ data }">
        <p v-if="data.webhookStatus" :class="['table-line', 'log-status', logStatus[data.webhookStatus]]">
          <strong>{{ data.webhookStatus }}</strong>
        </p>
        <div v-else class="none">
          <span />
        </div>
      </template>

      <template slot="renderFormat" slot-scope="{ data }">
        <p class="uncapitalize">
          <strong>{{ renderFormat[data.renderFormat] }}</strong>
        </p>
      </template>

      <template slot="async" slot-scope="{ data }">
        <span v-if="data.isAsync" class="checked-round">
          <checkedRound />
        </span>
        <div v-else class="none">
          <span />
        </div>
      </template>

      <template slot="s3" slot-scope="{ data }">
        <span v-if="data.isCustomBucket" class="checked-round">
          <checkedRound />
        </span>
        <div v-else class="none">
          <span />
        </div>
      </template>

      <template slot="executedAt" slot-scope="{ data }">
        <p class="table-line executed-at">{{ new Date(data.createdAt).toLocaleString('en-GB', { timeZone: 'UTC' }) }} (UTC)</p>
      </template>

      <template slot="dropdownIcon">
        <span class="dropdown-icon">
          <arrow />
        </span>
      </template>

      <template slot="dropdown" slot-scope="{ data }">
        <div class="details-code">
          <div>
            <p>Request</p>
            <app-code language="json" :code="JSON.stringify(data.request, null, 2)" />
          </div>
          <div>
            <p>Response</p>
            <app-code language="json" :code="JSON.stringify(data.response, null, 2)" />
          </div>
          <div v-if="data.webhookRespone">
            <p>Webhook response</p>
            <app-code language="json" :code="JSON.stringify(data.webhookRespone, null, 2)" />
          </div>
        </div>
      </template>

      <template slot="empty-table">
        <div class="empty-logs">
          <noData />
        </div>
      </template>
    </app-table>
  </main>
</template>

<script>
import logStatus from '@/services/data/logStatus';
import renderFormat from '@/enums/renderFormat';
import checkedRound from '@/assets/img/checked-round.svg?inline';
import arrow from '@/assets/img/arrow.svg?inline';
import noData from '@/assets/img/no-data.svg?inline';

export default {
  name: 'logs-table',

  components: {
    checkedRound,
    arrow,
    noData,
  },

  props: {
    logsData: {
      type: Array,
    },
    lighter: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableHeader: [
        { label: 'Request ID', key: 'id', size: 'small-3' },
        { label: 'Data source', key: 'source', size: 'small-1' },
        { label: 'Render status', key: 'render', size: 'small-1' },
        { label: 'Webhooks status', key: 'webhook', size: 'small-2' },
        { label: 'Format', key: 'renderFormat', size: 'small-1' },
        { label: 'Async', key: 'async', size: 'small-1' },
        { label: 'Custom S3', key: 's3', size: 'small-1' },
        { label: 'Executed at', key: 'executedAt', size: 'auto' },
        { label: '', key: 'dropdownIcon', size: 'shrink' },
      ],
      logStatus,
      renderFormat,
    };
  },
};
</script>

<style lang="sass">
.table
  width: 100%

  .empty-logs
    display: flex
    flex-direction: column
    align-items: center
    margin-left: -2.5rem
    svg
      margin-top: 10px
      max-width: 100px
      transform: translateX(15px)
    p
      padding: 2rem 0 0

  .none
    width: 20px

    span
      display: block
      margin: auto
      width: 10px
      height: 1px
      background: $primary40

  .table-line
    font-size: $font-3xs !important

  .table-pagination
    display: flex
    flex-direction: row
    justify-content: flex-end

  .executed-at
    color: $primary30 !important

  .dropdown-icon

    svg
      width: 1rem
      transition: all 0.3s ease-out

  .dropdown-icon-container
    justify-content: flex-end

  details[open] .dropdown-icon svg
    transform: rotate(-180deg)
    transition: all 0.3s ease-out

  .details-code
    > div
      p
        margin-bottom: 1rem
        font-weight: 500
      pre
        margin-bottom: 1.5rem
    > div:last-child
      pre
        margin-bottom: 0

  .log-status
    padding: 2px 8px !important
    user-select: none
    text-transform: uppercase
    border-radius: 1rem

    > strong
      color: $primary100

    &.queued
      background-color: lighten($accent100, 20%)

    &.processing
      background-color: $accent100

    &.success
      background-color: $green

    &.error
      background-color: $warning

</style>
