import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getLogs = async (limit, offset, search, from, to) => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/logs/`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(from && { from }),
        ...(to && { to }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getStats = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/statistics`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getLogs = getLogs;
api.getStats = getStats;

export default api;
