var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"table"},[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.logsData,"loading":_vm.isLoading,"isDropdown":true,"lighter":_vm.lighter},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"table-line"},[_c('strong',[_vm._v(_vm._s(data.requestId))])])]}},{key:"source",fn:function(ref){
var data = ref.data;
return [(data.request)?_c('p',{staticClass:"table-line"},[_vm._v(_vm._s(data.request.page.goto !== null ? 'URL' : 'HTML'))]):_c('div',{staticClass:"none"},[_c('span')])]}},{key:"render",fn:function(ref){
var data = ref.data;
return [_c('p',{class:['table-line', 'log-status', _vm.logStatus[data.renderStatus]]},[_c('strong',[_vm._v(_vm._s(data.renderStatus))])])]}},{key:"webhook",fn:function(ref){
var data = ref.data;
return [(data.webhookStatus)?_c('p',{class:['table-line', 'log-status', _vm.logStatus[data.webhookStatus]]},[_c('strong',[_vm._v(_vm._s(data.webhookStatus))])]):_c('div',{staticClass:"none"},[_c('span')])]}},{key:"renderFormat",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"uncapitalize"},[_c('strong',[_vm._v(_vm._s(_vm.renderFormat[data.renderFormat]))])])]}},{key:"async",fn:function(ref){
var data = ref.data;
return [(data.isAsync)?_c('span',{staticClass:"checked-round"},[_c('checkedRound')],1):_c('div',{staticClass:"none"},[_c('span')])]}},{key:"s3",fn:function(ref){
var data = ref.data;
return [(data.isCustomBucket)?_c('span',{staticClass:"checked-round"},[_c('checkedRound')],1):_c('div',{staticClass:"none"},[_c('span')])]}},{key:"executedAt",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"table-line executed-at"},[_vm._v(_vm._s(new Date(data.createdAt).toLocaleString('en-GB', { timeZone: 'UTC' }))+" (UTC)")])]}},{key:"dropdown",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"details-code"},[_c('div',[_c('p',[_vm._v("Request")]),_c('app-code',{attrs:{"language":"json","code":JSON.stringify(data.request, null, 2)}})],1),_c('div',[_c('p',[_vm._v("Response")]),_c('app-code',{attrs:{"language":"json","code":JSON.stringify(data.response, null, 2)}})],1),(data.webhookRespone)?_c('div',[_c('p',[_vm._v("Webhook response")]),_c('app-code',{attrs:{"language":"json","code":JSON.stringify(data.webhookRespone, null, 2)}})],1):_vm._e()])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"dropdownIcon"},[_c('span',{staticClass:"dropdown-icon"},[_c('arrow')],1)]),_c('template',{slot:"empty-table"},[_c('div',{staticClass:"empty-logs"},[_c('noData')],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }