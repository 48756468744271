<template>
  <section id="logs">
    <div class="page">
      <div class="search">
        <div>
          <app-search v-model="search" placeholder="Search..." />
        </div>
        <div>
          <app-datepicker
            placeholder="From :"
            insideText="From :"
            @input="getLogsFrom"
          />
          <app-datepicker
            placeholder="To :"
            insideText="To :"
            @input="getLogsTo"
          />
        </div>
      </div>
      <div class="table">
        <logs-table
          :logsData="logs.data"
          :loading="isLoading"
        />
        <p class="empty" v-if="!isLoading && logs.data.length === 0">
          No log found
          <slot v-if="search">for "{{ search }}"</slot>
          <slot v-if="filters.from"> from "{{ $dayjs(filters.from).format('MM/DD/YYYY') }}"</slot>
          <slot v-if="filters.to"> to "{{ $dayjs(filters.to).format('MM/DD/YYYY') }}"</slot>
          <slot v-if="!filters.to && !filters.from && search === null">-> <a :href="documentationUrl" target="_blank">Start using API</a>.</slot>
        </p>
        <div class="table-pagination">
          <app-pagination v-if="logs.metadata"
            :offset="logs.metadata.offset"
            :count="logs.metadata.count"
            :limit="limit"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import logsApi from '@/services/api/logs';
import LogStatus from '@/services/data/logStatus';
import utils from '@/services/utils/utils';
import LogsTable from './LogsTable.vue';

export default {
  name: 'logs',
  components: {
    LogsTable,
  },
  data() {
    return {
      logs: {
        data: [],
        metadata: {},
      },
      filters: {
        from: null,
        to: null,
      },
      search: null,
      limit: 10,
      LogStatus,
      isLoading: null,

      documentationUrl: process.env.VUE_APP_DOC_URL,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    await this.getLogs(this.computeOffset);
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getLogs(this.computeOffset);
        this.isLoading = false;
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    filters: {
      async handler() {
        await this.getLogs(this.computeOffset);
      },
      deep: true,
    },
  },
  methods: {
    async getLogs(offset) {
      this.isLoading = true;
      try {
        this.logs = await logsApi.getLogs(
          this.limit,
          offset,
          this.search,
          this.filters.from,
          this.filters.to,
        );
      } catch (err) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to get your logs',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.isLoading = false;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    getLogsFrom(from) {
      this.$set(this.filters, 'from', from);
    },
    getLogsTo(to) {
      this.$set(this.filters, 'to', to);
    },
  },
};
</script>

<style lang="sass">
#logs
  .page
    @include page

    .search
      display: flex
      justify-content: space-between
      align-items: center
      column-gap: 1rem

      > div:first-child
        width: 380px

      > div:last-child
        display: flex
        column-gap: 1rem

    .empty
      min-height: 120px
      text-align: center
      a
        color: $accent100
        text-decoration: underline
        font-size: 12px

</style>
